/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-04-29 10:24:32
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-06 16:23:55
 */
class Shared {
    /**
     * 获取 Token
     */
    getToken() {
        // 子应用独立运行时，在 localStorage 中获取 token
        return localStorage.getItem("UserTK") || "";
    }

    /**
     * 设置 Token
     */
    setToken(token) {
        // 子应用独立运行时，在 localStorage 中设置 token
        localStorage.setItem("UserTK", token);
    }
    getWorkApply() {

    }
}

class SharedModule {
    static shared = new Shared();

    /**
     * 重载 shared
     */
    static overloadShared(shared) {
        SharedModule.shared = shared;
    }

    /**
     * 获取 shared 实例
     */
    static getShared() {
        return SharedModule.shared;
    }
}

export default SharedModule;