<!--
 * @Description: 新建按钮
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-25 16:09:28
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-28 08:54:33
-->
<template>
  <el-button size="small" plain class="newadd" @click="clcikF">
    <div class="text">
      <span>{{ text }}</span>
      <i :class="icon + ' icon'"></i>
    </div>
  </el-button>
</template>

<script>
export default {
  name: "addNewBtn",
  components: {},
  props: {
    text: {
      type: String,
      default: "新建",
    },
    icon: {
      type: String,
      default: "el-icon-plus",
    },
  },
  data() {
    return {};
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {},
  methods: {
    clcikF() {
      this.$emit("add");
    },
  },
};
</script>
<style lang="scss" scoped>
.newadd {
  border: 0;
  font-size: 14px;

  .icon {
    font-size: 16px;
    margin-left: 9px;
    font-weight: 700;
    color: #56d79f;
  }
  .text {
    display: flex;
    align-items: center;
  }
}
</style>
