<!--
 * @Description: 左侧导航
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-04-28 15:20:27
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-03 18:02:51
-->
<template>
  <div class="menuLeft">
    <div class="logo_box">
      <img src="@/assets/images/iot.png" @click="toPathIndexF" alt />
    </div>
    <div class="listNav">
      <ul>
        <li
          :class="activePath.indexOf(item.url) != -1 ? 'active' : ''"
          v-for="(item, index) in navList"
          :key="index"
          @click="toPath(item.url)"
        >
          <img :src="$path + '/img/index/' + item.img + '.png'" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import base from "@/config";
export default {
  name: "LeftNav",
  data() {
    return {
      navList: [
        { name: "首页", img: "index", url: "/index" },
        { name: "设备", img: "device", url: "/equipment" },
        { name: "网关", img: "gateway", url: "/gateway" },
        { name: "报警", img: "warning", url: "/rule" },
      ],
      activePath: "",
    };
  },
  watch: {
    $route(to, from) {
      this.activePath = to.path;
    },
  },
  mounted() {
    this.activePath = this.$route.path;
  },
  methods: {
    toPathIndexF() {
      window.location.href = base.mainPath + "index";
    },
  },
};
</script>

<style  lang="scss" scoped>
.menuLeft {
  position: relative;
  overflow: hidden;
  padding-top: 15px;
  height: 100%;
  border-right: 1px solid #ebebeb;
  .logo_box {
    img {
      width: 34px;
      height: auto;
      cursor: pointer;
    }
  }
  .listNav {
    padding-top: 23px;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        width: 60px;
        height: 51px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        transition: all 0.3s;
        img {
          width: 20px;
          margin-bottom: 8px;
        }
        span {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #636572;
        }
        &:hover {
          background: #ebf2f0;
        }
      }
      li.active {
        background: #ebf2f0;
        span {
          color: #52d297;
        }
      }
    }
  }
}
</style>