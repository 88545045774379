/*
 * @Description: 选择场景时用到的接口
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2020-10-19 11:48:35
 * @LastEditors: 支磊
 * @LastEditTime: 2021-10-12 11:44:56
 */
import base from '@/config'; // 导入接口域名列表
import axios from '@/utils/cmshttps'; // 导入http中创建的axios实例
import qs from 'qs'

function headerF() {
  let header = {}
  if (localStorage.getItem("SiteId") && localStorage.getItem("SiteId") != -1) {
    header = {
      'SiteId': localStorage.getItem("SiteId")
    }
  }
  return header
}

export function healthIndicator(data) {
  let header = headerF()
  return axios.post(`${base.cmsBaseApi}/dashboard/v2/healthIndicator`, data, {
    headers: {
      ...header
    }
  });
}
export function deptSets() {
  let header = headerF()
  return axios.get(`${base.cmsBaseApi}/dept/v2/deptSets`, {
    headers: {
      ...header
    }
  })
}
export function sensorBatteryStat(data) { //传感器电量
  let header = headerF()
  return axios.post(`${base.cmsBaseApi}/dashboard/v2/sensorBatteryStat`, data, {
    headers: {
      ...header
    }
  });
}
export function ruleTypeRatio(data) { //报警类型统计
  let header = headerF()
  return axios.post(`${base.cmsBaseApi}/dashboard/v2/ruleTypeRatio`, data, {
    headers: {
      ...header
    }
  });
}
export function alarmRankings(data) { //设备状态历史统计
  let header = headerF()
  return axios.post(`${base.cmsBaseApi}/dashboard/v2/alarmRankings`, data, {
    headers: {
      ...header
    }
  });
}
export function assetRealtimeStat(data) { //设备实时状态
  let header = headerF()
  return axios.post(`${base.cmsBaseApi}/dashboard/v2/assetRealtimeStat`, data, {
    headers: {
      ...header
    }
  });
}
export function siteStat(data) { //设备运行
  let header = headerF()
  return axios.post(`${base.cmsBaseApi}/dashboard/v2/siteStat`, data, {
    headers: {
      ...header
    }
  });
}
export function alarmHandlingSummary(data) { //报警处理状况
  let header = headerF()
  return axios.post(`${base.cmsBaseApi}/dashboard/v2/alarmHandlingSummary`, data, {
    headers: {
      ...header
    }
  });
}
export function alarmHistoryStat(data) { //报警历史统计
  let header = headerF()
  return axios.post(`${base.cmsBaseApi}/dashboard/v2/alarmHistoryStat`, data, {
    headers: {
      ...header
    }
  });
}
export function site() {
  let header = headerF()
  return axios.get(`${base.cmsBaseApi}/dept/v2/site`, {
    headers: {
      ...header
    }
  });
}
export function getWsToken() { //获取websocket token
  return axios.get(`${base.cmsBaseApi}/user/v2/getWsToken`, );
}