/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-04-28 15:20:27
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-06 11:40:35
 */
// micro-app-vue/src/public-path.js
if (window.__POWERED_BY_QIANKUN__) {
    // 动态设置 webpack publicPath，防止资源加载出错
    // eslint-disable-next-line no-undef
    __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
}