/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-04-28 15:20:27
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-25 11:33:06
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/views/layout/Layout'
import devienceLayout from '@/views/layout/DevienceLayout'

Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'Layout',
        component: Layout,
        children: [{
                path: "/",
                redirect: "/index"
            }, // 首页
            {
                path: '/index',
                name: 'Index',
                component: () =>
                    import( /* webpackChunkName: "Index" */ '../views/index/index.vue'),
                meta: {
                    title: '首页',
                }
            },
            {
                path: '/equipment',
                name: 'Equipment',
                component: () =>
                    import( /* webpackChunkName: "Equipment" */ '../views/equipment/equipment.vue'),
                meta: {
                    title: '设备',
                }
            },
            {
                path: '/gateway',
                name: 'Gateway',
                component: () =>
                    import( /* webpackChunkName: "Gateway" */ '../views/gateway/gateway.vue'),
                meta: {
                    title: '网关',
                }
            },
            {
                path: '/rule',
                name: 'Rule',
                component: () =>
                    import( /* webpackChunkName: "Rule" */ '../views/rule/rule.vue'),
                meta: {
                    title: '规则',
                },
                children: [{
                        path: "/rule",
                        redirect: "/rule/list"
                    },
                    {
                        path: '/rule/list',
                        name: 'RuleList',
                        component: () =>
                            import( /* webpackChunkName: "RuleList" */ '../views/rule/ruleList/ruleList.vue'),
                        meta: {
                            title: '报警列表',
                        }
                    },
                    {
                        path: '/rule/historylist',
                        name: 'RuleHistoryList',
                        component: () =>
                            import( /* webpackChunkName: "RuleHistoryList" */ '../views/rule/historylist/historylist.vue'),
                        meta: {
                            title: '报警记录',
                        }
                    },
                    {
                        path: '/rule/nowlist',
                        name: 'RuleNowList',
                        component: () =>
                            import( /* webpackChunkName: "RuleNowList" */ '../views/rule/nowlist/nowlist.vue'),
                        meta: {
                            title: '实况信息',
                        }
                    },
                ]
            },

        ]
    },
    {
        path: '/equipment/devience',
        name: 'EquipmentDevience',
        component: devienceLayout,
        children: [{
                path: "/equipment/devience",
                redirect: "/equipment/devience/info"
            }, // 设备信息
            {
                path: '/equipment/devience/info',
                name: 'EquipmentDevienceInfo',
                component: () =>
                    import( /* webpackChunkName: "EquipmentDevienceInfo" */ '../views/devienceInfo/index.vue'),
                meta: {
                    title: '设备信息',
                }
            },
            {
                path: '/equipment/devience/gauging',
                name: 'EquipmentDevienceGauging',
                component: () =>
                    import( /* webpackChunkName: "EquipmentDevienceGauging" */ '../views/devienceGauging/index.vue'),
                meta: {
                    title: '设备测点',
                }
            },
            {
                path: '/equipment/devience/atlas',
                name: 'EquipmentDevienceAtlas',
                component: () =>
                    import( /* webpackChunkName: "EquipmentDevienceGauging" */ '../views/devienceAtlas/index.vue'),
                meta: {
                    title: '设备图谱',
                }
            },
        ]
    },
];

export default routes;