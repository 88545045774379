export default {
  install(Vue) {


    /**
     * @description: 获取当日日期
     * @param {*}
     * @return {string} 2012-11-13
     * @author: 支磊
     */
    Vue.prototype.$getDayTime = function () {
      let date = new Date;
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      if (m < 10) {
        m = "0" + m
      }
      if (d < 10) {
        d = "0" + d
      }
      let time = y + "-" + m + "-" + d
      return time
    }

    /**
     * query跳转路由方法
     *
     */
    Vue.prototype.toPath = function (paths, querys) {
      this.$router.push({
        path: paths,
        query: querys
      });
    }

    /**
     * params跳转路由方法,way为路由跳转的方式，true为replace,false为push，默认push,
     */
    Vue.prototype.paramsGo = function (names, param, way) {
      if (way) {
        this.$router.replace({
          name: names,
          params: param
        });
      } else {
        this.$router.push({
          name: names,
          params: param
        });
      }
    }
    /**
     * 获取屏幕宽高
     */
    Vue.prototype.getViewportSize = function () {
        return {
          width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
          height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        };
      },

      /**
       *  接口回复消息
       */
      Vue.prototype.ApiReply = function (res, successData, errorData) {
        if (res.data.status == "SUCCESS") {
          if (successData) {
            this.$message({
              message: successData,
              type: 'success'
            });

          }
          return true
        } else {
          if (errorData) {
            this.$message.error(errorData)
          } else {
            this.$message.error(res.data.data)
          }
          return false
        }
      },
      /**
       *  element form 不能为空
       */
      Vue.prototype.isNull = function (value, callback) {
        if (value === '' || value === null) {
          callback(new Error('不能为空'));
        } else {
          callback();
        }
      },
      /**
       *  cms接口回复消息
       */
      Vue.prototype.apiReply = function (res, successData, errorData) {
        if (res.data.errorCode == "0") {
          if (successData) {
            this.$message({
              message: successData,
              type: 'success'
            });
          }
          return true
        } else {
          if (errorData) {

            this.$message.error(errorData)
          } else {
            this.$message.error(res.data.errorMsg)

          }
          return false
        }
      },
      /**
       *  两数组相同元素
       */
      Vue.prototype.arrayIntersection = function (a, b) {
        var result = new Array();
        let bArr = JSON.parse(JSON.stringify(b));
        for (let i = 0; i < a.length; i++) {
          if (bArr.length == 0) {
            break
          }
          for (let j = 0; j < bArr.length; j++) {
            if (a[i] == bArr[j]) {
              result.push(a[i]);
              bArr = bArr.splice(j, 1)
              break
            }
          }
        }
        return result;
      },
      /**
       *  两数组不同元素
       */
      Vue.prototype.getArrDifference = function (arr1, arr2) {
        return arr1.concat(arr2).filter(function (v, i, arr) {
          return arr.indexOf(v) === arr.lastIndexOf(v);
        });
      }
    /**
     *  时间戳转时间
     */
    Vue.prototype.$formatDateTime = function (time, format) {
      var t = new Date(time);
      var tf = function (i) {
        return (i < 10 ? '0' : '') + i
      };
      return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
        let date = ""
        switch (a) {
          case 'yyyy':
            date = tf(t.getFullYear());
            break;
          case 'MM':
            date = tf(t.getMonth() + 1);
            break;
          case 'mm':
            date = tf(t.getMinutes());
            break;
          case 'dd':
            date = tf(t.getDate());
            break;
          case 'HH':
            date = tf(t.getHours());
            break;
          case 'ss':
            date = tf(t.getSeconds());
            break;
        }
        return date
      })
    }



  },
}