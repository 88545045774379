/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 张威
 * @Date: 2020-09-25 11:02:17
 * @LastEditors: 张威
 * @LastEditTime: 2020-09-25 11:03:18
 */
import Vue from 'vue';
import SvgIcon from '@/components/SvgIcon';

// 注册全局组件
Vue.component('svg-icon', SvgIcon);

const req = require.context('./svg', false, /\.svg$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(req);