/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 张威
 * @Date: 2020-09-17 18:12:20
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-13 09:58:20
 */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import systeam from './modules/systeam'
import state from './states';

import mutations from './mutations';

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    systeam,
  },
  state,
  mutations,
  getters
})

export default store