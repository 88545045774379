/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-13 09:57:21
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-13 11:08:38
 */
const state = {
    devicenames: '',
    industryImgUrl: "",
    bgcolor: "",
}
export default state