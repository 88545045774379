<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-04-28 15:20:27
 * @LastEditors: 支磊
 * @LastEditTime: 2021-10-22 11:38:38
-->
<template>
  <div id="app">
    <router-view v-if="isToken"></router-view>
  </div>
</template>

<script>
import base from "@/config";
import SharedModule from "@/shared";
import { getHoneywellToken, getSiteid } from "@/api/basics";
import { getWsToken } from "@/api/home";

export default {
  name: "Communication",

  data() {
    return {
      alarmLevelArr: ["正常", "低报", "高报", "高高报"],
      alarmTypeArr: ["幅值", "频谱", "突变"],
      isToken: false,
      websock: null,
      timeF: null,
      wsToken: "",
    };
  },
  destroyed() {
    clearTimeout(this.timeF);
    this.websock.close(); //离开路由之后断开websocket连接
  },
  mounted() {
    const shared = SharedModule.getShared();
    // 使用 shared 获取 token
    const token = shared.getToken();

    this.$store.commit("setInit", { token: token });
    // 未登录 - 返回主页
    let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
    if (!token || !WORK_APPLY || !WORK_APPLY.id || !WORK_APPLY.enterpriseId) {
      return (window.location.href = base.mainPath + "index");
    }
    this.$store.commit("setEnterpriseId", WORK_APPLY.enterpriseId);
    sessionStorage.setItem("systeam", JSON.stringify({ token: token }));
    this.getToken(token, WORK_APPLY);
  },
  methods: {
    getToken(token, WORK_APPLY) {
      let data = {
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.id, //物联应用id
      };
      getHoneywellToken(data).then((res) => {
        if (this.ApiReply(res)) {
          sessionStorage.setItem("cmsTK", res.data.data);
          this.getWsTokenF();
          this.getSiteidF(WORK_APPLY);
          this.isToken = true;
        } else {
          window.location.href = base.mainPath + "index";
        }
      });
    },
    getSiteidF(WORK_APPLY) {
      let data = {
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.id, //物联应用id
      };
      getSiteid(data).then((res) => {
        if (this.ApiReply(res)) {
          if (!res.data.data) {
            window.location.href = base.mainPath + "index";
            return;
          }
          this.$store.commit("setsiteid", res.data.data);
          localStorage.setItem("SiteId", res.data.data);
        }
      });
    },
    getWsTokenF() {
      getWsToken().then((res) => {
        if (this.apiReply(res)) {
          this.wsToken = res.data.data.token;
          this.initWebSocket(res.data.data.token);
        }
      });
    },
    HeartBeatF() {
      // 心跳检测
      clearTimeout(this.timeF);
      this.websocketsend("HeartBeat");

      this.timeF = setTimeout(() => {
        this.HeartBeatF();
      }, 30 * 1000);
    },
    initWebSocket(token) {
      //初始化weosocket
      // const wsuri = " wss://cms.honcloud.honeywell.com.cn/ws/v2?token=" + token;
      const wsuri = " wss://honeywell.iqingneng.com/ws/v2?token=" + token;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      this.websocketsend("HeartBeat");
      this.HeartBeatF();
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket(this.wsToken);
    },
    websocketonmessage(e) {
      //数据接收
      const redata = JSON.parse(e.data);
      for (let i = 0; i < redata.length; i++) {
        this.showWarningF(redata[i]);
      }
      console.log("数据接收", redata);
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      this.initWebSocket(this.wsToken); //连接断开后重连
      console.log("断开连接", e);
    },
    defineCallBack($event) {
      this.$notify.closeAll();
    },
    showWarningF(data) {
      let time = this.$formatDateTime(data.alarmTime, "yyyy-MM-dd HH:mm");
      let _this = this;
      const h = this.$createElement;
      this.$notify({
        title: this.alarmLevelArr[data.alarmLevel] + "  " + time,
        duration: 0,
        dangerouslyUseHTMLString: true,
        type: "error",

        message:
          // `<div class="notify"><div></div><div class="allOff"></div></div>`,
          h("div", { class: "notify" }, [
            h(
              "div",
              null,
              `${data.deviceFullDept}|${data.deviceName}|${data.deviceName}|${
                this.alarmTypeArr[data.alarmType]
              }`
            ),
            h(
              "div",
              {
                class: "allOff",
                on: {
                  click: (res) => {
                    this.defineCallBack(res);
                  },
                },
              },
              "全部关闭"
            ),
          ]),
      });
    },
  },
};
</script>

<style lang="scss">
.el-notification {
  width: auto !important;
  .notify {
    width: 460px;
    display: flex;
    justify-content: space-between;
    .allOff {
      cursor: pointer;
      &:hover {
        color: $main_color;
      }
    }
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
.el-dialog {
  border-radius: 10px !important;
  padding: 8px;
  text-align: left;
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 24px;
  }

  p {
    color: #232626;
  }
  .el-input {
    width: 367px;
  }
  .dialogtitle {
    position: relative;
    padding-left: 20px;
    font-size: 16px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 16px;
      background-color: $main_color;
    }
  }
}
.is-leaf {
  font-weight: 400 !important;
}
// 分页
.el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #56d79f !important;
  background-color: #f0f4ff !important;
  border: 1px solid #56d79f !important;
  border-radius: 5px !important;
}
.btn-prev {
  width: 28px !important;
  background-color: #ffffff !important;
  border: 1px solid #bbbfc4 !important;
  border-radius: 5px !important;
  padding: 0px !important;
}
.btn-next {
  width: 28px !important;
  background-color: #ffffff !important;
  border: 1px solid #bbbfc4 !important;
  border-radius: 5px !important;
  padding: 0px !important;
}
</style>
