<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-04-28 17:27:58
 * @LastEditors: 支磊
 * @LastEditTime: 2021-04-30 09:35:04
-->
<template>
  <div class="Layout" id="IOTLayout">
    <el-container class="Main">
      <el-aside width="80px" id="Nav">
        <leftNav></leftNav>
      </el-aside>
      <el-container class="content">
        <!-- <el-header>
        <headC
          v-if="navData.length != 0"
          :navData="navData"
          :Enterprise="Enterprise"
        ></headC>
      </el-header> -->
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import leftNav from "@/components/LeftNavComponents"; //左侧导航
export default {
  name: "Layout",
  components: { leftNav },
  data() {
    return {};
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
#IOTLayout {
  height: 100%;
  .Main {
    height: 100%;
  }
  /deep/.el-main {
    padding: 0;
  }
}
</style>
