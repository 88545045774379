/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-04-29 16:31:42
 * @LastEditors: 支磊
 * @LastEditTime: 2021-12-02 11:44:43
 */
// 正式
module.exports = {
    title: '轻能',
    baseApi: 'https://java.iqingneng.com/api',
    // cmsBaseApi: 'https://cms.honcloud.honeywell.com.cn/api',
    cmsBaseApi: 'https://honeywell.iqingneng.com/api',
    publicPath: "https://iot.iqingneng.com/",
    mainPath: "https://www.iqingneng.com/"
}