/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2020-12-13 14:58:58
 * @LastEditors: 支磊
 * @LastEditTime: 2021-09-27 17:44:00
 */
const state = {
  token: "",
  workApply: {},
  enterpriseId: "",
  siteid: ""

}
const mutations = {

  setInit(state, data) {
    state.token = data.token;
    state.workApply = data.workApply;

  },
  setEnterpriseId(state, data) {
    state.enterpriseId = data;
  },
  setsiteid(state, data) {
    state.siteid = data;
  },
}
const actions = {

}
export default {
  state,
  mutations,
  actions
}