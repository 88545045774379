<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 张威
 * @Date: 2020-09-25 10:58:37
 * @LastEditors: 张威
 * @LastEditTime: 2020-09-25 11:10:00
-->
<template>
    <svg :class="svgClass" aria-hidden="true" v-on="$listeners">
        <use :xlink:href='iconName' />
    </svg>
</template>

<script>
export default {
    name: 'SvgIcon',
    props: {
        iconClass: {
            type: String,
            require: true
        },
        className: {
            type: String,
            default: ''
        }
    },
    computed: {
        iconName() {
            return `#icon-${this.iconClass}`;
        },
        svgClass() {
            if (this.className) {
                return `svg-icon ${this.className};`
            } else {
                return 'svg-icon';
            }
        }
    }
}
</script>

<style scoped>
.svg-icon{
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>