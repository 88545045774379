/*
 * @Description: 选择场景时用到的接口
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2020-10-19 11:48:35
 * @LastEditors: 支磊
 * @LastEditTime: 2022-03-17 10:53:51
 */
import base from '@/config'; // 导入接口域名列表
import axios from '@/utils/https'; // 导入http中创建的axios实例
import qs from 'qs'
// 进入霍尼韦尔查询site_id
export function getSiteid(data) {
    return axios.post(`${base.baseApi}/honeywell/getSiteid`, qs.stringify(data));
}
// 根据分组id获取下级数据
export function getHoneywellToken(data) {
    return axios.post(`${base.baseApi}/honeywell/getHoneywellToken`, qs.stringify(data));
}
export function getEnterpriseName(data) { //企业名称+logo
    return axios.post(`${base.baseApi}/honeywell/getEnterpriseName`, qs.stringify(data));
}
// 获取设备分组下的设备
export function queryDevice(data) {
    return axios.post(`${base.baseApi}/device/basics/queryDevice`, qs.stringify(data));
}
//  获取设备分组 
export function queryGroupDevice(data) {
    return axios.post(`${base.baseApi}/device/basics/queryGroupDevice`, qs.stringify(data));
}
// 导入设备上传图片
export function uploadFile(data) {
    return axios.post(`${base.baseApi}/honeywell/uploadFile`, data);
}
export function updateFaultUser(data) {
    return axios.post(`${base.baseApi}/newfault/updateFaultUser`, qs.stringify(data));
}
export function removeAddInfo(data) { //新增信息-删
    return axios.post(`${base.baseApi}/device/basics/removeAddInfo`, data);
}
export function insertAddInfo(data) { //新增信息-增（改）
    return axios.post(`${base.baseApi}/device/basics/addInfo`, qs.stringify(data));
}
export function getFaultUser(data) { //部分成员-搜索成员
    return axios.post(`${base.baseApi}/newfault/getFaultUser`, qs.stringify(data));
}
export function updatePhone(data) { //修改设备名，联系人，联系人电话
    return axios.post(`${base.baseApi}/device/basics/updatePhone`, qs.stringify(data));
}
export function updateBaseInfo(data) { //修改设备基础信息
    return axios.post(`${base.baseApi}/device/basics/updateBaseInfo`, qs.stringify(data));
}
export function queryDType() { //设备认证--获取设备分类列表
    return axios.post(`${base.baseApi}/device/basics/queryDType`);
}
export function queryDTypeInfo(data) { //设备认证---选择不同类型带出不同数据
    return axios.post(`${base.baseApi}/device/basics/queryDTypeInfo`, qs.stringify(data));
}
export function searchUser(data) { //部分成员-搜索成员
    return axios.post(`${base.baseApi}/invitation/getSearchUser`, qs.stringify(data));
}
export function getNextMenuAndUser(data) { //部分成员-获取下级菜单与成员
    return axios.post(`${base.baseApi}/invitation/getNextMenuAndUser`, qs.stringify(data));
}
export function searchDepartment(data) { //部分成员-搜索部门
    return axios.post(`${base.baseApi}/invitation/getSearchDepartment`, qs.stringify(data));
}
export function queryGroup(data) {
    return axios.post(`${base.baseApi}/device/basics/queryGroupNew`, qs.stringify(data))
}
export function getDepartmentEN(data) { //获取企业部门
    return axios.post(`${base.baseApi}/enterprise/getDepartmentEN`, qs.stringify(data));
}
export function selectUsersByDId(data) {
    return axios.post(`${base.baseApi}/enterprise/selectUsersByDId`, qs.stringify(data));
}

export function updateQandHDevice(data) {
    return axios.post(`${base.baseApi}/honeywell/updateQandHDevice`, qs.stringify(data));
}
export function queryDeviceInfoByhDeviceId(data) {
    return axios.post(`${base.baseApi}/honeywell/queryDeviceInfoByhDeviceId`, qs.stringify(data));
}
export function queryGatewayGroup(data) { // 获取网关分组  https://gitee.com/jufa/dashboard/wikis/752504?doc_id=752504&sort_id=4051475
    return axios.post(`${base.baseApi}/honeywell/queryGatewayGroup`, qs.stringify(data));
}
export function createGatewayGroup(data) { // 新增分组  https://gitee.com/jufa/dashboard/wikis/752504?doc_id=752504&sort_id=4051477  
    return axios.post(`${base.baseApi}/honeywell/createGatewayGroup`, qs.stringify(data));
}
export function updateGatewayGroup(data) { // 编辑分组  https://gitee.com/jufa/dashboard/wikis/752504?doc_id=752504&sort_id=4051478  
    return axios.post(`${base.baseApi}/honeywell/updateGatewayGroup`, qs.stringify(data));
}
export function removeGatewayGroup(data) { // 删除网关分组  https://gitee.com/jufa/dashboard/wikis/752504?doc_id=752504&sort_id=4051479
    return axios.post(`${base.baseApi}/honeywell/removeGatewayGroup`, qs.stringify(data));
}
export function addGateway(data) { // 新增网关后请求轻能 https://gitee.com/jufa/dashboard/wikis/752504?doc_id=752504&sort_id=4051746  
    return axios.post(`${base.baseApi}/honeywell/addGateway`, qs.stringify(data));
}
export function updateGateway(data) { // 删除网关后请求轻能   https://gitee.com/jufa/dashboard/wikis/752504?doc_id=752504&sort_id=4051759  
    return axios.post(`${base.baseApi}/honeywell/updateGateway`, qs.stringify(data));
}
export function getDeviceNameByHoneywell(data) { // 霍尼设备id对应设备名称   https://gitee.com/jufa/dashboard/wikis/jufa%2Fjufa_java_interface_document?doc_id=752504&sort_id=4086384
    return axios.post(`${base.baseApi}/honeywell/getDeviceNameByHoneywell`, qs.stringify(data));
}
export function getDeviceImg(data) { //获取设备图片 https://gitee.com/jufa/dashboard/wikis/752504?doc_id=752504&sort_id=3964739
    return axios.post(`${base.baseApi}/honeywell/getDeviceImg`, qs.stringify(data));
}
export function updateDeviceImg(data) { //更换设备图片 https://gitee.com/jufa/dashboard/wikis/752504?doc_id=752504&sort_id=3964801
    return axios.post(`${base.baseApi}/honeywell/updateDeviceImg`, qs.stringify(data));
}
//首页数据
export function getHoneyWellIndex(data) {
    return axios.post(`${base.baseApi}/honeywell/getHoneyWellIndex`, qs.stringify(data));
}
//首页分组
export function getHoneyDeviceGroup(data) {
    return axios.post(`${base.baseApi}/honeywell/getHoneyDeviceGroup`, qs.stringify(data));
}