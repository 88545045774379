<!--
 * @Description: 左侧导航
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-04-28 15:20:27
 * @LastEditors: 支磊
 * @LastEditTime: 2022-03-17 11:29:12
-->
<template>
  <div class="menuLeft">
    <div class="logo_box">
      <img src="@/assets/images/iot.png" @click="toPathIndexF" alt />
    </div>
    <div class="listNav">
      <ul>
        <li class="device" @click="toPath('/equipment')">
          <img :src="$path + '/img/index/device.png'" alt="" />
          <span>设备</span>
        </li>
        <p class="line"></p>
        <li
          :class="activePath == item.url ? 'active' : ''"
          v-for="(item, index) in navList"
          :key="index"
          @click="toPathF(item)"
        >
          <img :src="$path + '/img/device/' + item.img + '.png'" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import base from "@/config";
export default {
  name: "LeftNav",
  data() {
    return {
      navList: [
        { name: "详情", img: "info", url: "/equipment/devience/info" },
        { name: "测点", img: "gauging", url: "/equipment/devience/gauging" },
        { name: "图谱", img: "atlas", url: "/equipment/devience/atlas" },
      ],
      activePath: "",
    };
  },
  watch: {
    $route(to, from) {
      this.activePath = to.path;
    },
  },
  computed: {
    queryPath() {
      return this.$route.query;
    },
  },
  mounted() {
    this.activePath = this.$route.path;
  },
  methods: {
    toPathIndexF() {
      window.location.href = base.mainPath + "index";
    },
    toPathF(item) {
      // if (item.name == "详情") {
      let deviceId = sessionStorage.getItem("deviceId");
      this.toPath(item.url, {
        ...this.queryPath,
      });
      // } else {
      //   this.toPath(item.url);
      // }
    },
  },
};
</script>

<style  lang="scss" scoped>
.menuLeft {
  position: relative;
  overflow: hidden;
  padding-top: 15px;
  height: 100%;
  border-right: 1px solid #ebebeb;
  .logo_box {
    img {
      width: 34px;
      height: auto;
      cursor: pointer;
    }
  }
  .listNav {
    padding-top: 18px;
    .line {
      width: 54px;
      height: 2px;
      background: #ededed;
      margin: 12px 0;
    }
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        width: 60px;
        height: 51px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        transition: all 0.3s;
        img {
          width: 20px;
          margin-bottom: 8px;
        }
        span {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #859088;
        }
        &:hover {
          background: #fff5e1;
          span {
            color: #ffa900;
          }
        }
      }
      li.device {
        &:hover {
          background: #ebf2f0;
          span {
            color: #859088;
          }
        }
      }
      li.active {
        background: #fff5e1;
        span {
          color: #ffa900;
        }
      }
    }
  }
}
</style>