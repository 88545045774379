/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2020-12-17 11:33:44
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-17 16:41:43
 */
import './public-path';
import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import routes from './router/router';
import store from './store'
import '../public/static/theme/index.css';
import SharedModule from "@/shared";
//公共css
import '@/assets/css/normalize.css';
// 引入通用方法
import utils from './utils/phone'
Vue.use(utils);
import vuescroll from 'vuescroll/dist/vuescroll-native';
Vue.config.productionTip = false;
//引入 icons
import '@/icons/index'
import VueCropper from 'vue-cropper' //裁切图片
Vue.use(VueCropper)
import AddNewBtn from "./components/public/addNewBtn.vue" //新建按钮
Vue.component('AddNewBtn', AddNewBtn)
import {
    Container,
    Aside,
    Main,
    Input,
    Tree,
    Tooltip,
    Dialog,
    Button,
    Message,
    MessageBox,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Pagination,
    Select,
    Upload,
    CheckboxGroup,
    Checkbox,
    Image,
    Option,
    Form,
    FormItem,
    Loading,
    Scrollbar,
    Table,
    TableColumn,
    DatePicker,
    Tag,
    InfiniteScroll,
    Carousel,
    CarouselItem,
    Notification,
    Tabs,
    TabPane,
    RadioGroup,
    RadioButton,
    Radio
} from 'element-ui'
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(InfiniteScroll);
Vue.use(Container);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Input);
Vue.use(Tree);
Vue.use(Tooltip);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Pagination);
Vue.use(CheckboxGroup);
Vue.use(Upload);
Vue.use(Select);
Vue.use(Checkbox);
Vue.use(Option);
// 图片
Vue.use(Image);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Loading);
Vue.use(Scrollbar)
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(Tag);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(RadioGroup);
Vue.use(RadioButton);

// 消息提示
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$path = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
Vue.use(vuescroll);
let router = null;
let instance = null;

function render(props = {}) {
    const {
        container,
        shared = SharedModule.getShared()
    } = props;
    SharedModule.overloadShared(shared);
    router = new VueRouter({
        base: window.__POWERED_BY_QIANKUN__ ? '/IOT' : '/',
        mode: 'history',
        routes,
    });

    instance = new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount(container ? container.querySelector('#app') : '#app');
}



if (!window.__POWERED_BY_QIANKUN__) {
    render();
}
export async function bootstrap() {
    console.log('[vue] vue app bootstraped');
}

export async function mount(props) {
    console.log('[vue] props from main framework', props);
    // storeTest(props);
    render(props);
}

export async function unmount() {
    instance.$destroy();
    instance.$el.innerHTML = '';
    instance = null;
    router = null;
}
Vue.directive('title', {
    inserted: function (el, binding) {
        document.title = el.dataset.title
    }
})